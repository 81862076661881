<template>
  <v-row align="center" v-if="isFirstPage">
    <v-col class="top-row-details">
      <div class="bold">
        <span>
          {{ date ? `${$t('generics.date')}:` : $t('generics.noDate') }}
        </span>
        <span>{{ date }}</span>
        <span style="margin-right: 4px">{{ day }}'</span>
      </div>

      <div>
        <span class="bold">
          {{ addressTitle ? addressTitle : $t('generics.noAddress') }}
        </span>
      </div>
      <div class="bold">
        {{ hour ? `${$t('generics.hour')}:` : $t('generics.noDelivaryTime') }}
        <span class="font-weight-light">{{ hour }}</span>
        <span
          v-if="order.additionalInfo"
          class="font-weight-light"
          style="padding-right: 6px"
        >
          ({{ order.additionalInfo }})
        </span>
      </div>
      <div
        class="bold"
        v-if="
          order.delivery.type === 'delivery' &&
          address &&
          !/^\s*$/.test(address)
        "
      >
        <div v-for="(a, i) in address" :key="a" style="max-width: 80%">
          <span :style="i ? 'opacity: 0.0;' : ''" style="padding-left: 3px">
            {{ $t('generics.addressForDelivery') }}:</span
          >
          <span class="font-weight-light">{{ a }}</span>
        </div>
      </div>

      <div class="bold d-flex">
        <span v-if="order.info">{{ order.info }}</span>
        <span v-if="order.info && order.email" style="margin: 0 8px">|</span>
        <span v-if="order.email">{{ order.email }}</span>
      </div>
    </v-col>  
    <v-col cols="3" style="text-align: end !important; padding-top: 0">
      <img src="/newLogo.png" width="160" />
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col />
    <v-col
      cols="3"
      style="text-align: end !important; padding-top: 0; align-self: flex-start"
    >
      <img class="mt-8" src="/newLogo.png" width="160" />
    </v-col>
  </v-row>
</template>
<script>
import dayjs from 'dayjs'

export default {
  name: 'OrderHeader',
  props: {
    order: Object,
    date: String,
    hour: String,
    addressTitle: String,
    address: Array,
    isFirstPage: Boolean,
  },
  computed: {
    day() {
      return this.order?.delivery?.day
        ? this.$t(`days.${dayjs(this.order.delivery.day).format('d')}.key`)
        : ''
    },
  },
}
</script>
<style lang="scss" scoped>
.top-row-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.bold {
  font-weight: 700;
}
</style>
