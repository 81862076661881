<template>
  <div>
    <filter-app-bar
      v-if="isFilterDefaultValueLoaded"
      bin
      :bin-model="bin"
      @update:binModel="bin = !bin"
      @handleClick="createBid"
      @updateStart="updateStart"
      @updateEnd="updateEnd"
      @updateDeliveryDate="updateDeliveryDate"
      :showBtn="true"
      :defaultStart="start"
      :defaultEnd="end"
      :defaultIsDeliveryDateValue="isDeliveryDate"
      :defaultFilterObj="defaultFilter"
      :showSwitch="true"
      v-model="showDateFilter"
      :isBidsPage="true"
    />
    <v-container v-if="isFilterDefaultValueLoaded">
      <h2 v-if="showDateFilter" class="text-center text-h5">
        {{
          !end ? dayBySlash(start) : `${dayBySlash(end)} - ${dayBySlash(start)}`
        }}
      </h2>
      <h1 class="text-center mb-5 mt-3">
        {{ $tc('bid.index', 2) }} {{ bin ? `- ${$t('generics.bin')}` : null }}
      </h1>
      <v-card v-if="bids.length" id="orders-table" class="pa-0" rounded="lg">
        <v-card-title class="d-flex align-center">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                icon
                :label="$t('generics.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-if="bids.length"
            :headers="headers"
            :items="bids"
            :loading="loading"
            :search="search"
            :footer-props="{ 'items-per-page-options': [100, 30, 50, -1] }"
            id="orders-table"
            sort-by="delivery.day"
          >
            <template v-slot:item.client.fullname="{ item }">
              <div>
                {{ item.client ? item.client.fullname : 'N/A' }}
                <v-icon v-if="isWoocommerce(item)">mdi-web</v-icon>
              </div>
            </template>
            <template v-slot:item.delivery.day="{ item }">
              <span>{{ formatDate(item.delivery.day) }}</span>
            </template>
            <template v-slot:item.sum="{ item }">
              <span v-if="bin">{{
                item.orderNum || item.orderNumOfBid || ''
              }}</span>
              <span v-else>{{ item.sum }} ₪</span>
            </template>
            <template v-slot:item.options="{ item }">
              <div class="d-flex" style="gap: 10px">
                <v-tooltip bottom v-if="!bin">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      size="21"
                      @click="editItem(item)"
                      :disabled="item.approved"
                      color="black"
                    >
                      mdi-briefcase-edit
                    </v-icon>
                  </template>
                  <span>{{ $t('bid.edit') }}</span>
                </v-tooltip>
                <v-btn @click="openPdf(item)" icon>
                  <v-icon size="21" color="black"> mdi-download </v-icon></v-btn
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      size="21"
                      @click="bin ? restoreItem(item) : deleteItem(item)"
                      :color="bin ? 'green' : 'red'"
                    >
                      {{ bin ? 'mdi-delete-restore' : 'mdi-trash-can-outline' }}
                    </v-icon>
                  </template>
                  <span v-if="bin">{{ $t('generics.restoreFromBin') }}</span>
                  <span v-else>{{ $t('bid.delete') }}</span>
                </v-tooltip>
                <v-tooltip v-if="item.order" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a @click="editItem(item.order, true)" class="center-all">
                      <v-icon v-bind="attrs" v-on="on" size="21">
                        mdi-clipboard-check-outline
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('bid.approved') }}</span>
                </v-tooltip>
                <v-icon v-if="bin && item.orderNum" color="red"
                  >mdi-trash-can-outline</v-icon
                >
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <div v-else class="center-all mt-15">
        <h3>{{ $t('bid.noBids') }}</h3>
      </div>
    </v-container>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import FilterAppBar from '../layouts/FilterAppBar.vue'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import genericServices from '../services/generics'
import Times from '../helpers/times'
import FiltersCaching from '@/services/FiltersCaching.js'
import axios from 'axios'
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export default {
  name: 'BidsPage',
  components: { FilterAppBar },
  data() {
    return {
      bin: false,
      headers: [
        {
          text: this.$t('generics.supply'),
          value: 'delivery.day',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('client.name'),
          value: 'client.fullname',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('generics.mobile'),
          value: 'client.phone',
          class: 'primary lighten-1',
        },
        {
          text: this.$tc('menu.menu', 1),
          value: 'menusTitle',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('generics.sumWithTax'),
          value: 'sum',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('generics.options'),
          value: 'options',
          class: 'primary lighten-1',
        },
        {
          value: 'info',
          class: 'd-none',
          cellClass: 'd-none',
        },
        {
          value: 'menu.[0].title',
          class: 'hide',
          cellClass: 'hide',
        },
      ],
      loading: false,
      chosen: null,
      search: '',
      addDialog: false,
      editDialog: false,
      start: null,
      end: null,
      isDeliveryDate: null,
      showDateFilter: false,
    }
  },
  computed: {
    isFilterDefaultValueLoaded() {
      return this.start && this.end && this.isDeliveryDate !== null
    },
    defaultFilter() {
      const today = dayjs()
      return {
        start: today.format(Times.dateFormat),
        end: today.format(Times.dateFormat),
      }
    },
    bids() {
      let bids = this.$store.getters.bids
      bids = bids.map(bid => ({
        ...bid,
        menusTitle: bid.menus?.[0]?.title || bid?.menu?.title || '',
        sum: this.calculateMenuPrice(bid),
      }))
      if (!this.showDateFilter) return bids
      return genericServices.filterOrders(
        bids,
        this.start,
        this.end,
        this.isDeliveryDate
      )
    },
  },
  methods: {
    isWoocommerce(order) {
      return order?.menus?.[0]?.title.includes('Woocommerce')
    },
    openPdf(item) {
      let pdfLink = `${document.location.origin}`
      pdfLink += `/orders/pdf/${item._id}`
      pdfLink += item.orderNum ? '' : `?menu=${item.menus?.[0]?._id}`
      window.open(pdfLink, '_blank')
    },
    async loadData() {
      this.loading = true
      await this.$store.dispatch('bid/index', { bin: this.bin })
      this.loading = false
    },
    async editItem(item, fetchByID = false) {
      let isEditOrder = false
      if (fetchByID) {
        item = (await axios.get(`/order/${item}`)).data
        isEditOrder = true
      }
      this.chosen = item
      this.$router.push({
        name: 'BidHandler',
        params: { isNew: false, bid: item, isEditOrder },
        query: { _id: item._id },
      })
    },
    duplicateItem(item) {
      alert(`This will duplicate the bid, including the ONLY the menu`)
    },
    async deleteItem(item) {
      let { isConfirmed } = await this.$createSwal({
        title: this.$t('order.deleteOrderMsg'),
      })
      if (isConfirmed) {
        await this.$store.dispatch('bid/destroy', { id: item._id })
      }
    },
    async restoreItem(item) {
      let { isConfirmed } = await this.$createSwal({
        title: this.$t('generics.restoreMessage'),
      })
      if (!isConfirmed) return
      if (item.orderNum) {
        await this.$store.dispatch('order/restore', item)
      } else {
        await this.$store.dispatch('bid/restore', { id: item._id })
      }
    },
    calcSupplyDateString(supplyDate) {
      const date = dayjs(supplyDate)
      const dayInWeek = date.day()
      return `${this.$t(`days[${dayInWeek}].name`)} ${date.format('DD/MM/YY')}`
    },
    createBid() {
      this.$router.push({ name: 'BidHandler' })
    },
    approveBid(bid) {
      this.chosen = bid
      this.$router.push({ name: 'BidApproval', params: { bid } })
    },
    updateStart(v) {
      this.start = v
    },
    updateEnd(v) {
      this.end = v
    },
    updateDeliveryDate(v) {
      this.isDeliveryDate = v
    },
    formatDate(str) {
      return Times.extractDateString(str)
    },
    calculateProductPrice(p) {
      return p.product.price * p.quantity
    },
    calculateMenuPrice(bid) {
      let price = 0
      bid?.menus?.[0]?.products?.forEach(
        p => (price += this.calculateProductPrice(p))
      )
      return price
    },
    dayBySlash(date) {
      return Times.dayBySlash(date)
    },
  },
  async mounted() {
    if (!this.items?.length) {
      await this.loadData()
    }
    const { start, end, isDeliveryDate, showDateFilter } = FiltersCaching.get({
      type: 'all',
    })
    this.showDateFilter = showDateFilter === undefined ? false : showDateFilter
    this.start = start || this.defaultFilter.start
    this.end = end || this.defaultFilter.end
    this.isDeliveryDate = isDeliveryDate !== undefined ? isDeliveryDate : true
  },
  watch: {
    bin(newValue) {
      this.loadData()
      if (newValue) {
        this.headers.forEach((header, index) => {
          if (header.value === 'sum') {
            header.text = this.$t('order.num')
          }
        })
      } else {
        this.headers.forEach((header, index) => {
          if (header.value === 'sum') {
            header.text = this.$t('generics.sumWithTax')
          }
        })
      }
    },
    showDateFilter(value) {
      FiltersCaching.save({ type: 'all', key: 'showDateFilter', value })
    },
    start(value) {
      FiltersCaching.save({ type: 'all', key: 'start', value })
    },
    end(value) {
      FiltersCaching.save({ type: 'all', key: 'end', value })
    },
    isDeliveryDate(value) {
      FiltersCaching.save({ type: 'all', key: 'isDeliveryDate', value })
    },
  },
}
</script>
