<template>
  <div class="mt-10 d-flex justify-center align-center flex-column">
    <v-btn class @click="generate" v-if="dataLoaded">{{
      $t('pdf.generate')
    }}</v-btn>
    <div v-if="dataLoaded">
      <div v-for="order in localOrders" :key="order._id">
        <OrderPdf ref="pdfContent" :orderObj="order" />
      </div>
    </div>

    <!-- Loading overlay for both initial loading and PDF generation -->
    <v-overlay
      :value="loading || initialLoading"
      :opacity="0.8"
      :z-index="9999"
    >
      <div class="text-center">
        <v-progress-circular
          :indeterminate="initialLoading"
          :value="loading ? progress : 0"
          color="white"
          size="64"
        >
          <template v-if="loading">{{ Math.round(progress) }}%</template>
        </v-progress-circular>
        <div class="white--text mt-4">
          {{ initialLoading ? $t('pdf.preparing') : $t('pdf.generating') }}
        </div>
        <div class="white--text mt-2" v-if="timeEstimate">
          {{ timeEstimate }}
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import OrderPdf from './OrderPdf.vue'
import html2pdf from 'html2pdf.js'
import { PDFDocument } from 'pdf-lib'
import dayjs from 'dayjs'
export default {
  name: 'MultipleOrdersPdf',
  components: {
    OrderPdf,
  },
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localOrders: [],
      dataLoaded: false,
      loading: false,
      initialLoading: true,
      progress: 0,
      timeEstimate: '',
      startTime: null,
      averageTimePerPdf: null,
    }
  },
  computed: {
    dateRange() {
      return {
        min: this.$route.query.startDate,
        max: this.$route.query.endDate,
      }
    },
    fileName() {
      return `orders_${this.dateRange.min}_${this.dateRange.max}`
    },
  },
  async created() {
    if (!this.dateRange.min || !this.dateRange.max) {
      console.error('Date range is required')
      return
    }

    try {
      this.initialLoading = true
      let orders
      if (this.orders.length) {
        orders = this.orders
      } else {
        await this.$store.dispatch('order/index', this.dateRange)
        orders = this.$store.getters.orders
      }

      this.localOrders = orders.sort(this.compareDeliveryTimes)
      // Rest of the created hook
      await this.$nextTick()
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.dataLoaded = true
    } catch (error) {
      console.error('Error fetching orders:', error)
    } finally {
      this.initialLoading = false
    }
  },
  methods: {
    parseDeliveryTime(time) {
      return dayjs(`2000-01-01 ${time}`)
    },

    getTimeComponents(delivery) {
      const [startTime, endTime] = delivery.startHour?.split(' - ') || [delivery.startHour]
      return {
        start: startTime,
        end: endTime || startTime // fallback to startTime if no endTime
      }
    },

    compareDeliveryTimes(a, b) {
      // Compare delivery days
      const dayDiff = dayjs(a.delivery.day).diff(dayjs(b.delivery.day))
      if (dayDiff !== 0) return dayDiff

      // Get time components
      const aTime = this.getTimeComponents(a.delivery)
      const bTime = this.getTimeComponents(b.delivery)

      // Compare end times
      const endDiff = this.parseDeliveryTime(aTime.end).diff(this.parseDeliveryTime(bTime.end))
      if (endDiff !== 0) return endDiff

      // Compare start times
      const startDiff = this.parseDeliveryTime(aTime.start).diff(this.parseDeliveryTime(bTime.start))
      if (startDiff !== 0) return startDiff

      // Prioritize single times over ranges
      return (aTime.end !== aTime.start) - (bTime.end !== bTime.start)
    },

    updateTimeEstimate(completed, total) {
      if (!this.startTime) {
        this.startTime = Date.now()
        return
      }

      const elapsed = Date.now() - this.startTime
      const averageTimePerItem = elapsed / completed
      const remainingItems = total - completed
      const estimatedSeconds = Math.round(
        (averageTimePerItem * remainingItems) / 1000
      )

      if (estimatedSeconds < 60) {
        this.timeEstimate = this.$t('pdf.remainingTime', {
          count: estimatedSeconds,
        })
      } else {
        const minutes = Math.floor(estimatedSeconds / 60)
        const seconds = estimatedSeconds % 60
        this.timeEstimate = this.$t('pdf.remainingTimeMinutes', {
          minutes: minutes,
          seconds: seconds,
        })
      }
    },

    async generate() {
      try {
        this.loading = true
        this.progress = 0
        this.startTime = null
        this.timeEstimate = ''

        const elements = [...document.querySelectorAll('.html2pdf__page-break')]
        const pdfBlobs = []

        // Generate individual PDFs with progress
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i]
          const pdf = await html2pdf()
            .set(
              this.$refs.pdfContent[0].$children[0].$refs.html2Pdf.setOptions()
            )
            .from(element)
            .toPdf()
            .outputPdf('blob')

          pdfBlobs.push(pdf)
          this.progress = ((i + 1) / elements.length) * 90
          this.updateTimeEstimate(i + 1, elements.length)
        }

        // Update progress message for merging phase
        this.timeEstimate = this.$t('pdf.merging')

        // Combine PDFs
        const mergedPdf = await PDFDocument.create()
        for (let i = 0; i < pdfBlobs.length; i++) {
          const pdfBlob = pdfBlobs[i]
          const pdfBytes = await pdfBlob.arrayBuffer()
          const pdf = await PDFDocument.load(pdfBytes)
          const pages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
          pages.forEach(page => mergedPdf.addPage(page))
          // Update progress for merging (last 10%)
          this.progress = 90 + ((i + 1) / pdfBlobs.length) * 10
        }

        // Save and download
        const mergedPdfBytes = await mergedPdf.save()
        const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = `${this.fileName}.pdf`
        link.click()
        URL.revokeObjectURL(url)
      } catch (error) {
        console.error('Error generating PDF:', error)
      } finally {
        this.loading = false
        this.timeEstimate = ''
      }
    },
  },
}
</script>
